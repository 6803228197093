<template>
  <div>
    <p class="h4 pt-3 pb-4">发放积分(用户)</p>
    <div class="mb-3">
      <div style="display:inline-block">
        <el-input v-model="projectid" placeholder="请输入projectid"></el-input>
        <div class="issue-err">{{validID(projectid, 'projectid')}}</div>
      </div>
    </div>
    <div class="mb-3">
      <el-button type="success" @click="addAllUser()">批量添加</el-button>
      <el-button type="primary" @click="addUser()">添加用户</el-button>
      <el-button
        type="primary"
        :disabled="validID(projectid, 'projectid') !== ''"
        @click="rewardFirst()"
        v-show="tableData.length > 0">
        发放积分
      </el-button>
    </div>
    <div class="issuc-table">
      <el-table :data="tableData" style="width:100%">
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column label="用户名">
          <template slot-scope="scope">
            <span v-show="!scope.row.show">{{scope.row.username}}</span>
            <div v-show="scope.row.show" style="height:45px;">
              <el-input v-model="tableData[scope.$index].username" placeholder="用户名" size="mini"></el-input>
              <div class="issue-err">{{validName(scope.row.username)}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="积分">
          <template slot-scope="scope">
            <span v-show="!scope.row.show">{{scope.row.points}}</span>
            <div v-show="scope.row.show" style="height:45px;">
              <el-input v-model="tableData[scope.$index].points" placeholder="积分数量" size="mini"></el-input>
              <div class="issue-err">{{validPonit(scope.row.points)}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button class="issuc-edit" type="text" size="small"  icon="el-icon-check" @click="complete(scope.row, scope.$index)" v-show="scope.row.show"></el-button>
            <el-button class="ml-0 issuc-edit" type="text" size="small"  icon="el-icon-edit-outline" @click="editUser(scope.$index)" v-show="!scope.row.show"></el-button>
            <el-button class="issuc-edit" type="text" size="small"  icon="el-icon-delete" @click="delUser(scope.$index)"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="提示" :visible.sync="addAllVisible" width="30%" :before-close="handleClose">
      <div>
        <div class="mb-3">
          <span class="btn btn-primary btn-sm fileinput-button">
            <span>选择文件</span>
            <input type="file" name="xlfile" ref="xlf" @change="handleFile">
          </span>
          <a class="ml-3" href="./static/files/批量发送积分.xlsx">下载用户模板</a>
        </div>
        <el-checkbox v-model="resetUsers">清空现有用户</el-checkbox>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import { rewardPoints } from '@/api/points'

export default {
  name: 'senduser',
  data () {
    return {
      projectid: '',
      tableData: [],
      addAllVisible: false,
      resetUsers: false
    }
  },
  computed: {
    validID () {
      return (id, msg) => {
        let res = ''
        // 存在时，整数数字校验
        if (id) {
          const type = Number(id)
          if (isNaN(Number(type)) || id.toString().indexOf('.') !== -1) {
            res = `${msg}格式错误`
          }
        } else {
          res = `${msg}不能为空`
        }
        return res
      }
    },
    validName () {
      return name => {
        let res = ''
        // 有内容时，用户名是否合法，简单校验
        if (name) {
          const reg1 = /^[a-zA-Z][a-zA-Z0-9_-]{4,29}$/
          const rule = reg1.test(name) && name.length >= 5 && name.length <= 30
          res = rule ? '' : '请输入合法的用户名'
        }
        return res
      }
    },
    validPonit () {
      return point => {
        let res = ''
        // 存在时，整数数字校验
        if (point) {
          const type = Number(point)
          if (isNaN(Number(type)) || point.toString().indexOf('.') !== -1 || point.toString()[0] === '0') {
            res = '积分应为大于0的整数数字'
          }
        }
        return res
      }
    }
  },
  methods: {
    addAllUser () {
      this.addAllVisible = true
      this.resetUsers = false
    },
    handleFile (e) {
      const files = e.target.files
      if (files[0]) {
        this.readFile(files[0])
      }
    },
    readFile (file) {
      const vm = this
      const reader = new FileReader()
      reader.onload = function (e) {
        const data = e.target.result // 获取表格
        const wb = XLSX.read(data, { type: 'binary' }) // 读取Excel信息
        const firstSheetName = wb.SheetNames[0] // 获取表一名称
        const worksheet = wb.Sheets[firstSheetName] // 获取表一
        const json = XLSX.utils.sheet_to_json(worksheet) // 解析成json格式
        const newJson = json.map(item => {
          return Object.assign({ show: false }, item)
        })
        // 清空
        if (vm.resetUsers) {
          vm.tableData = []
        }
        // 赋值
        vm.tableData = vm.tableData.concat(newJson)
        vm.addAllVisible = false
        vm.$refs.xlf.value = ''
      }
      // 读取完成时，onload函数被执行，readAsBinaryString返回结果为二进制
      reader.readAsBinaryString(file)
    },
    addUser () {
      this.tableData.push({
        username: '',
        points: 0,
        show: true
      })
    },
    // 删除用户
    delUser (index) {
      this.tableData.splice(index, 1)
    },
    // 编辑用户
    editUser (index) {
      this.tableData[index].show = true
    },
    // 完成校验
    complete (row, index) {
      const { username, points } = row
      // 为空提示
      if (!username || !points) {
        this.$notify.error({
          title: '错误',
          message: '请填写信息'
        })
        return
      }
      // 验证信息错误提示
      if (this.validName(username) || this.validPonit(points)) {
        this.$notify.error({
          title: '错误',
          message: '请正确填写信息'
        })
        return
      }
      this.tableData[index].show = false
    },
    // 发送积分
    rewardFirst () {
      // 验证是否全部信息都确认了，未确认的给提示
      for (const i in this.tableData) {
        if (this.tableData[i].show) {
          this.$notify.error({
            title: '错误',
            message: '请确认数据是否全部填写完毕'
          })
          return
        }
      }
      // 验证通过，填写备注
      this.$prompt('请输入备注', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then((res) => {
        const msg = res.value
        // 填写发送密码
        this.$prompt('请输入积分密码', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /\w/,
          inputErrorMessage: '积分密码不能为空'
        }).then(({ value }) => {
          this.rewardSecond(msg, value)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消发送'
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消发送'
        })
      })
    },
    rewardSecond (msg, password) {
      // 生成json
      const userInfo = this.tableData.map(item => {
        return Object.assign({}, { username: item.username, point: Number(item.points) })
      })
      // 请求接口
      rewardPoints({
        checksum: password,
        projectid: Number(this.projectid),
        msg: msg || '',
        userPoint: userInfo
      }).then(
        res => {
          // 通过提示成功与失败个数
          const { success, fail, failusers } = res.data
          this.$alert(
            `成功：<strong>${success || '0'}个</strong>
            失败：<strong>${fail || '0'}个</strong>
            ${failusers ? '<br>无效用户名：' + failusers.join(' , ') : ''}`,
            '积分发送完成',
            {
              confirmButtonText: '确定',
              dangerouslyUseHTMLString: true,
              callback: action => {
                console.log('成功')
              }
            }
          )
        }
      ).catch(
        err => {
          console.log(err)
        }
      )
    }
  }
}
</script>
<style>
.issuc-table {
  max-width: 1000px;
  border: 1px solid #EBEEF5;
  padding: 24px;
}
.issue-err {
  color: #F56C6C;
  font-size: 12px;
  line-height: 1.5;
  text-indent: 5px;
}
.issuc-edit {
  font-size: 18px;
  padding: 0;
}
.issuc-edit:focus, .issuc-edit:active {
  outline: none;
}
.fileinput-button {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.fileinput-button input {
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0;
  /* -ms-filter: "alpha(opacity=0)"; */
}
</style>
