import request from './index'

export function rewardLogs (data) {
  return request({
    url: '/api/reward/logs',
    method: 'post',
    data
  })
}

export function rewardPoints (data) {
  return request({
    url: '/api/reward/user',
    method: 'post',
    data
  })
}

export function rewardPointsTeam (data) {
  return request({
    url: '/api/team/balance/pay',
    method: 'post',
    data
  })
}

export function getExchange () {
  return request({
    url: '/api/balance/cny2usd/info',
    method: 'get'
  })
}

export function postExchange (data) {
  return request({
    url: '/api/balance/cny2usd/update',
    method: 'post',
    data: {
      from: 'cny',
      to: 'usd',
      ...data
    }
  })
}
